<template>
  <div class="flex justify-center mt-10">
    <div id='veriff-root'></div>
  </div>
</template>

<script>
import { Veriff } from '@veriff/js-sdk';
import {createVeriffFrame} from '@veriff/incontext-sdk';
import config from "@/config";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import store from "@/store/index"

export default {
  name: "VeriffForm",
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('verification', ['currentVerification'])
  },
  methods: {
    ...mapActions('alert', ['error', 'success'])
  },
  mounted() {
    if(this.userData.isVerified || this.currentVerification.limit.limitReached || this.currentVerification.init || !this.userData.isSubscriptionActive) {
      router.push({name: 'settings'})
    } else {
      const _this = this;
      const veriff = Veriff({
        host: process.env.VUE_APP_VERIFF_HOST,
        apiKey: process.env.VUE_APP_VERIFF_KEY,
        parentId: 'veriff-root',
        onSession: function(err, response) {
          createVeriffFrame({
                url: response.verification.url, onEvent: function(msg) {
                  if(msg === 'CANCELED') {
                    store.dispatch('alert/error', {message: _this.trans('ALERT:VerificationStopped')});
                  } else {
                    router.push({name: 'settings'}, () => {
                      store.dispatch('alert/success', {message: _this.trans('ALERT:VerificationProceeding')});
                    })
                  }
                }
              },
          );
        }
      });
      veriff.setParams({
        vendorData: this.userData.id.toString()
      });
      veriff.mount({
        formLabel: {
          givenName: this.trans('LABEL:VeriffGivenName'),
          lastName: this.trans('LABEL:VeriffLastName'),
        },
        submitBtnText: this.trans('LABEL:VeriffGetVerified'),
        loadingText: this.trans('LABEL:Loading')
      });
    }
  }
}
</script>
